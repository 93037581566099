import JSZip from "jszip";
import { DownloadContent } from "./DownloadContent";

export const DownloadZippedContent = (extension, filename, data) => {
    if (extension === "zip") {
        try {
            const jsZip = new JSZip();
            jsZip.loadAsync(data).then((zip) => {
                Object.keys(zip.files).forEach((internalFileName) => {
                    zip.files[internalFileName].async("arraybuffer").then((fileData) => {
                        const fileNameToDownload = filename.replace(/\.[^/.]+$/, "");
                        DownloadContent(fileNameToDownload, fileData, undefined);
                    });
                });
            });
        } catch(e) {
            DownloadContent(filename, data, undefined);  
        }
    }
    else {
        DownloadContent(filename, data, undefined);
    }
};