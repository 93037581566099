import React from "react";
import { EnvironmentType } from "../functions/EnvironmentType";
import { useCheckMobileScreen } from "..";

export function EnvName() {
  const env = EnvironmentType();
  const isMobile = useCheckMobileScreen();

  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      {(env !== "prod" && !isMobile) && (
        <h1 className="d-inline-block text-uppercase font-weight-bold text-danger">
          {env}
        </h1>
      )}
    </div>
  );
}
