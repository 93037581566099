import React from "react";
import Can from "../../AWS/Can";
import ability from "../../AWS/ability";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";
import { getMenuItemActive, GetTenant } from "../../../utils";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSelector, shallowEqual } from "react-redux"

export function Aside() {
  const location = useLocation();

  const { tenant } = useSelector(
    (state) => ({
      tenant: GetTenant(),
    }),
    shallowEqual
  );

  return (
    <>
      {(ability.can("view", "rt_user") || ability.can("view", "rt_user_accountant")) && (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(location, [
            "/rt_user",
            "/retail/cashregisters",
          ])}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className={"menu-link menu-toggle "} to="/rt_user">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar2.svg")}
              />
            </span>
            <span className={"menu-text "}>
              <FormattedMessage id="SERVICE.ASIDE.RT_USER" />
            </span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">
                    <FormattedMessage id="SERVICE.ASIDE.RT_USER" />
                  </span>
                </span>
              </li>

              {!tenant?.isAccountant && (
                <Can I="view" a="ecr">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      location,
                      "/retail/cashregisters"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className={"menu-link "} to="/retail/cashregisters">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className={"menu-text "}>
                        <FormattedMessage id="SERVICE.ASIDE.RETAIL.RECORDERS" />
                      </span>
                    </NavLink>
                  </li>
                </Can>
              )}

              <Can I="view" a="rt_user_history">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/rt_user/history"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/rt_user/history">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.RT_USER.HISTORY" />
                    </span>
                  </NavLink>
                </li>
              </Can>

              <Can I="view" a="rt_user_aggregated">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/rt_user/aggregated"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/rt_user/aggregated">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.RT_USER.AGGREGATED" />
                    </span>
                  </NavLink>
                </li>
              </Can>

              <Can I="view" a="rt_user_collector">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/rt_user/collector"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/rt_user/collector">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.RT_USER.COLLECTOR" />
                    </span>
                  </NavLink>
                </li>
              </Can>

              <Can I="manage" a="rt_user_accountant_machines">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/rt_user/accountant_cashregisters"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/rt_user/accountant_cashregisters">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.RT_USER.CASH_REGISTERS" />
                    </span>
                  </NavLink>
                </li>
              </Can>

              <Can I="manage" a="rt_user_accountant_fiscaldata">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/rt_user/fiscaldata"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/rt_user/fiscaldata">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.RT_USER.FISCAL_DATA" />
                    </span>
                  </NavLink>
                </li>
              </Can>

              <Can I="view" a="rt_user_settings">
                <li
                  className={`menu-item ${getMenuItemActive(
                    location,
                    "/rt_user/settings"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className={"menu-link "} to="/rt_user/settings">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className={"menu-text "}>
                      <FormattedMessage id="SERVICE.ASIDE.RT_USER.SETTINGS" />
                    </span>
                  </NavLink>
                </li>
              </Can>
            </ul>
          </div>
        </li>
      )}
    </>
  );
}
