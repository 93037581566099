import * as Yup from "yup";
import * as restApi from "../../modules/Admin/rest/api";
import { YupCacheValidation } from "./YupCacheValidation";

const checkOnDb = async (value, checkExistance) => {
  return new Promise((resolve, reject) => {
    if (value && (value.length === 16 || value.length === 15)) {
      restApi.checkMoitoIotCode(value)
        .then(result => {
          if (result?.data?.exists) {
            resolve(false);
          }
          else {
            resolve(true);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    }
    else {
      resolve(true);
    }
  });
}

export function MoitoIOTRegistrationCodeValidator(dispatch, intl) {
  var result = Yup.string();
  result = result.test('checkLength', intl.formatMessage({ id: "MOITO_IOT.INVALID_LENGTH" }), val => val ? (val.length === 16 || val.length === 15) : true)
    .test('checkSlash', intl.formatMessage({ id: "MOITO_IOT.SLASH_IN_CODE_MESSAGE" }), val => val ? !val.includes("/") && !val.includes("\\") : true)
    .test('checkSpaces', intl.formatMessage({ id: "MOITO_IOT.INVALID_CODE" }), val => val ? !val.includes(" ") : true)
    .test('checkTemp', intl.formatMessage({ id: "MOITO_IOT.TEMP_IN_CODE_MESSAGE" }), val => val ? !val.includes("TEMP_") : true);

  const checkOnDbTest = YupCacheValidation((value) => checkOnDb(value));

  result = result.test(
    "checkMoitoIOTCodeExistance",
    intl.formatMessage({ id: "MOITO_IOT.CODE_EXISTANCE_ERROR"}),
    checkOnDbTest,
  );

  return result;
}
